body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* for clearing padding on tabs content when nesting forms inside tabs */
.adm-tabs.content-no-pad .adm-tabs-content {
  padding: 0;
}

.main-sider * {
  border-inline-end: none !important;
}

.ant-layout.rave .ant-breadcrumb-link span {
  cursor: pointer;
}

.ant-layout.rave {
  padding: 0 50px;
}

.ant-layout.rave .ant-layout-content {
  padding: 24px;
  width: 100%;
}

.ant-layout.rave > * {
  max-width: 1600px;
}

.ant-layout.rave .route-nav .ant-btn {
  margin: auto 0;
}

input::placeholder {
  opacity: 0;
}

.ant-pro-table .ant-pro-card .ant-pro-card-body {
  padding-inline: 0 !important;
  padding-block: 0 !important;
}

.quote-autocomplete-dropdown {
  width: 25%;
  max-width: 25%;
  display: inline-block;
}
